export default Object.freeze({
  NOME_SERVICO: "Softruv Mobile",
  ANO_CRIACAO: 2018,

  // URL_API: "http://localhost:8080/servico-softruv/api",
  // USUARIO_ID_PROPRIETARIO: "109",
  // USUARIO_EMAIL: "juarez",
  // USUARIO_SENHA: "nkozx_E67",

  // URL_API: "http://192.168.100.3:8080/servico-softruv/api",
  // USUARIO_ID_PROPRIETARIO: "59",
  // USUARIO_EMAIL: "juarez",
  // USUARIO_SENHA: "nkozx_E67",

  URL_API: "https://mob.softruv.com.br/servico-softruv-1/api",
  USUARIO_ID_PROPRIETARIO: "",
  USUARIO_EMAIL: "",
  USUARIO_SENHA: "",

  // URL_API: "http://tomcat9.frontech.com.br/servico-softruv-1/api",
  // USUARIO_ID_PROPRIETARIO: "",
  // USUARIO_EMAIL: "",
  // USUARIO_SENHA: "",

  // USUARIO_EMAIL: "tecnico@frontech.com.br",
  // USUARIO_SENHA: "nkozx_E67",
  // USUARIO_EMAIL: "juarezna@gmail.com",
  // USUARIO_SENHA: "nkozx_E70",
  // USUARIO_EMAIL: "",
  // USUARIO_SENHA: "",
});
